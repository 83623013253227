// src/pages/ViewMap/CompletionModal.js
import React from "react";
import "./CompletionModal.css";

// If you have a trophy asset, update the path below. Otherwise, remove or replace the image.
import trophy from "../../assets/trophy.png"; 

const CompletionModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {trophy && <img src={trophy} alt="Trophy" className="trophy" />}
        <h2>Congratulations!</h2>
        <p>You have completed the challenge. Your challenge will be checked and an email sent once the medal has been shipped</p>
        <button onClick={onClose}>Continue</button>
      </div>
    </div>
  );
};

export default CompletionModal;

