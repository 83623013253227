// src/pages/ViewMap/ViewMap.js
import React, { useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Marker,
  InfoWindow,
  GroundOverlay,
} from "@react-google-maps/api";
import { milestone_percentage_config } from "../../globalConfig/milestones";
import logo from "../../assets/icons8-left-50.png";
import lockedwaypointLogo from "../../assets/locked-waypoint.png";
import unlockedWaypointLogo from "../../assets/unlocked-waypoint.png";
import openableWaypointLogo from "../../assets/openable-waypoint.png";
import flagIcon from "../../assets/flag-icon.png";
import startIcon from "../../assets/start-icon.png";
import paceIcon from "../../assets/paceicon.png";
import "./style.css";

import icon from "../../assets/marker.png";
import userIcon from "../../assets/userMarker.png";
import icon_milestone from "../../assets/milestone.png";
import {
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getUsersByProductId,
  getChallengeById,
  getWaypointsById,
  getUserActivity,
  checkChallengeValidity,
} from "../../globalService/api.service";
import WaypointModal from "./WaypointModal";
import EvidenceModal from "./EvidenceModal";
import Activity from "./Activity";
import CompletionModal from "./CompletionModal"; // Ensure this path is correct

const googleMapsApiKey = "AIzaSyAFgWyTzNPhooiqiZJIr-Judt3Wo14w6GE";
const containerStyle = {
  width: "100%",
  height: "80vh",
  touchAction: "none",
};

const getWaypointLogo = (is_openable, is_unlocked) => {
  if (is_openable || is_unlocked) {
    if (is_unlocked) return unlockedWaypointLogo;
    else if (is_openable) return openableWaypointLogo;
  } else return lockedwaypointLogo;
};

function ViewMap(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
  });

  // State declarations
  const [challenge, setChallenge] = React.useState(null);
  const [google, setGoogle] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [polyline, setPolyline] = React.useState(null);
  const [markers, setMarkers] = React.useState([]);
  const [infoWindow, setInfoWindow] = React.useState(null);
  const [center, setCenter] = React.useState({ lat: 0, lng: 0 });
  const [waypointsData, setWaypointsData] = React.useState([]);
  const [waypoints, setWaypoints] = React.useState([]);
  const [waypoint, setWaypoint] = React.useState(null);
  const [activity, setActivity] = React.useState(null);
  const [isOn, setIsOn] = React.useState(false);
  const [pace, setPace] = React.useState(null);
  const [paceMarker, setPaceMarker] = React.useState(null);
  const [isWpModalOpen, setIsWpModalOpen] = React.useState("");
  const [isEvModalOpen, setIsEvModalOpen] = React.useState(false);
  const [showCompletionModal, setShowCompletionModal] = React.useState(false);

  const params = useParams();
  const { productId, userId } = params;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const app = searchParams.get("app");

  const onMapLoad = async () => {
    const myGoogle = window.google;
    setGoogle(myGoogle);
  };

  const goBack = () => {
    navigate(-1);
  };

  // Open/Close modal handlers
  const handleOpenWpModal = (wp, position, is_openable, is_unlocked) => {
    if (is_openable || is_unlocked) {
      setIsWpModalOpen(is_unlocked ? "is_unlocked" : "is_openable");
      setWaypoint(wp);
    } else {
      setInfoWindow(
        <InfoWindow onCloseClick={() => setInfoWindow(null)} position={position}>
          <div>
            <p>
              This waypoint is locked. Your previous waypoints may be locked or
              you have not yet reached this point.
            </p>
          </div>
        </InfoWindow>
      );
    }
  };

  const handleOpenEvModal = () => {
    setIsEvModalOpen(true);
  };

  const handleCloseWpModal = () => {
    setIsWpModalOpen("");
    setWaypoint(null);
  };

  const handleCloseEvModal = () => {
    setIsEvModalOpen(false);
  };

  const options = {
    strokeColor: "#00BFFF",
    strokeOpacity: 1.0,
    strokeWeight: 4,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  // Center the map based on challenge coordinates
  useEffect(() => {
    if (challenge) {
      const { pathCoordinates } = challenge;
      const getCenterLat = () => {
        const center = pathCoordinates[Math.floor(pathCoordinates.length / 2)];
        return center.lat;
      };
      const getCenterLng = () => {
        const center = pathCoordinates[Math.floor(pathCoordinates.length / 2)];
        return center.lng;
      };
      setCenter({
        lat: pathCoordinates ? getCenterLat() : 51.49336,
        lng: pathCoordinates ? getCenterLng() : -0.13752,
      });
    }
  }, [challenge]);

  // Initial API calls
  useEffect(() => {
    localStorage.setItem("brandId", params.brandId);
    getUser();
    getChallenge();
    getWaypointsFromAPI();
    if (userId) generateWaypoints();
    if (app) {
      handleOpenEvModal();
    }
  }, []);

  const getWaypointsFromAPI = async () => {
    let data = await getWaypointsById(productId, userId);
    if (!Array.isArray(data)) data = [];
    setWaypointsData(data);
  };

  const getUser = async () => {
    const paceData = await checkChallengeValidity(productId, userId);
    setPace(parseFloat(paceData.pace));
    let userData = await getUsersByProductId(productId, userId);
    if (!Array.isArray(userData)) userData = [];
    let userActivity = await getUserActivity(productId, userId);
    setUsers(userData);
    setActivity(userActivity);
  };

  // Create pace marker
  useEffect(() => {
    if (google && polyline && pace) {
      const position = getPositionByPercentage(pace);
      setPaceMarker(<Marker position={position} icon={paceIcon} />);
    }
  }, [google, polyline, pace]);

  const getChallenge = async () => {
    const data = await getChallengeById(productId);
    if (data.length)
      setChallenge({
        ...data[0],
        pathCoordinates: JSON.parse(data[0].pathCoordinates || null),
      });
  };

  useEffect(() => {
    if (polyline) {
      challenge && getMarkers(userId);
      getWaypointsFromAPI();
    }
  }, [polyline, users]);

  useEffect(() => {
    getMarkers(userId);
  }, [isOn]);

  useEffect(() => {
    if (userId && waypointsData && google) generateWaypoints();
  }, [waypointsData]);

  // Check user progress and show completion modal when percentage >= 100%
  // Only show if the modal hasn't been shown for this challenge (checked via localStorage)
  useEffect(() => {
    if (users && userId && !localStorage.getItem(`completionModalShown_${productId}`)) {
      const currentUser = users.find((user) => user.userId === userId);
      if (currentUser && currentUser.percentage >= 100) {
        setShowCompletionModal(true);
      }
    }
  }, [users, userId, productId]);

  const markerClickedHandler = (user, position) => {
    setInfoWindow(
      <InfoWindow onCloseClick={() => setInfoWindow(null)} position={position}>
        <div>
          {userId && userId === user.userId && (
            <>
              <span style={{ color: "black", fontWeight: "bold" }}>
                Id:{" "}
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  {user.userId}
                </span>
              </span>
              <br />
            </>
          )}
          <span style={{ color: "black", fontWeight: "bold" }}>
            Name:{" "}
            <span style={{ color: "bronze", fontWeight: "bold" }}>
              {user.name}
            </span>
          </span>
          <br />
          <span style={{ color: "black", fontWeight: "bold" }}>
            Distance:{" "}
            <span style={{ color: "bronze", fontWeight: "bold" }}>
              {user.distanceKm} km
            </span>
          </span>
          <br />
          <span style={{ color: "black", fontWeight: "bold" }}>
            %:{" "}
            <span style={{ color: "bronze", fontWeight: "bold" }}>
              {user.percentage}
            </span>
          </span>
        </div>
      </InfoWindow>
    );
  };

  function generateWaypoints() {
    const waypointUI = waypointsData.map((waypoint) => {
      const position = getPositionByPercentage(waypoint.percentage);
      return (
        <Marker
          onClick={() =>
            handleOpenWpModal(
              waypoint,
              position,
              waypoint.is_openable,
              waypoint.is_unlocked
            )
          }
          position={position}
          icon={getWaypointLogo(waypoint.is_openable, waypoint.is_unlocked)}
          key={new Date().toISOString()}
          animation={google.maps.Animation.DROP}
        />
      );
    });
    if (
      challenge &&
      challenge.pathCoordinates &&
      challenge.pathCoordinates.length > 0
    ) {
      const firstCoordinate = challenge.pathCoordinates[0];
      const startCoordinateMarker = (
        <Marker
          position={{ lat: firstCoordinate.lat, lng: firstCoordinate.lng }}
          icon={startIcon}
          key="startCoordinate"
        />
      );
      const lastCoordinate =
        challenge.pathCoordinates[challenge.pathCoordinates.length - 1];
      const lastCoordinateMarker = (
        <Marker
          position={{ lat: lastCoordinate.lat, lng: lastCoordinate.lng }}
          icon={flagIcon}
          key="lastCoordinate"
        />
      );
      setWaypoints([
        ...waypointUI,
        startCoordinateMarker,
        lastCoordinateMarker,
      ]);
    } else {
      setWaypoints([...waypointUI]);
    }
  }

  function getMarkers(id = undefined) {
    const userMarkers = users
      .map((user) => {
        const position = getPositionByPercentage(user.percentage);
        if (user.userId)
          return (
            <Marker
              onClick={() => markerClickedHandler(user, position)}
              position={position}
              animation={google.maps.Animation.BOUNCE}
              icon={
                challenge?.customUserIcon ? challenge.customUserIcon : userIcon
              }
              key={user.userId}
            />
          );
        return null;
      })
      .filter((e) => e);
    setMarkers(userMarkers);

    if (users && users.length && userId) {
      const currentUser = users.find((user) => user.userId === userId);
      if (currentUser) {
        const position = getPositionByPercentage(currentUser.percentage);
        setCenter(position);
      }
    }
  }

  const getPositionByPercentage = (percentage) => {
    if (!percentage) percentage = 0.01;
    if (percentage > 100) percentage = 100;
    google.maps.LatLng.prototype.distanceFrom = function (newLatLng) {
      const EarthRadiusMeters = 6378137.0;
      const lat1 = this.lat();
      const lon1 = this.lng();
      const lat2 = newLatLng.lat();
      const lon2 = newLatLng.lng();
      const dLat = (lat2 - lat1) * Math.PI / 180;
      const dLon = (lon2 - lon1) * Math.PI / 180;
      const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos(lat1 * Math.PI / 180) *
          Math.cos(lat2 * Math.PI / 180) *
          Math.sin(dLon / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return EarthRadiusMeters * c;
    };

    google.maps.Polyline.prototype.Distance = function () {
      let dist = 0;
      for (let i = 1; i < this.getPath().getLength(); i++) {
        dist += this.getPath().getAt(i).distanceFrom(
          this.getPath().getAt(i - 1)
        );
      }
      return dist;
    };

    const distance = Math.floor((polyline.Distance() * percentage) / 100);
    if (!distance) return undefined;
    function GetPointAtDistance(metres) {
      if (metres === 0) return polyline.getPath().getAt(0);
      if (metres < 0) return null;
      if (polyline.getPath().getLength() < 2) return null;
      let dist = 0,
        olddist = 0,
        i;
      for (
        i = 1;
        i < polyline.getPath().getLength() && dist < metres;
        i++
      ) {
        olddist = dist;
        dist += polyline.getPath().getAt(i).distanceFrom(
          polyline.getPath().getAt(i - 1)
        );
      }
      if (dist < metres) return null;
      const p1 = polyline.getPath().getAt(i - 2);
      const p2 = polyline.getPath().getAt(i - 1);
      const m = (metres - olddist) / (dist - olddist);
      return new google.maps.LatLng(
        p1.lat() + (p2.lat() - p1.lat()) * m,
        p1.lng() + (p2.lng() - p1.lng()) * m
      );
    }
    let position = GetPointAtDistance(distance);
    return { lat: position.lat(), lng: position.lng() };
  };

  const onLoad = (poly) => {
    setPolyline(poly);
  };

  const handleToggle = () => {
    setIsOn((prevState) => !prevState);
  };

  return isLoaded ? (
    <div className={!challenge ? "center" : ""}>
      {challenge ? (
        <>
          <div className="navbar">
            <div className="nav" onClick={goBack}>
              <img src={logo} style={{ height: 40 }} alt="logo" />
            </div>
            <div className="heading">
              {challenge.name}: {challenge.totalDistanceKm} KM/{" "}
              {(challenge.totalDistanceKm * 0.621371).toFixed(1)} Mi
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={challenge.bounds && challenge.bounds !== "null" ? 7.3 : 16}
              onLoad={onMapLoad}
              options={{
                restriction:
                  challenge.bounds && challenge.bounds !== "null"
                    ? { latLngBounds: JSON.parse(challenge.bounds) }
                    : null,
                minZoom:
                  challenge.bounds && challenge.bounds !== "null" ? 7.3 : null,
              }}
            >
              <>
                {challenge?.bounds && challenge?.mapOverlay && (
                  <GroundOverlay
                    key="url"
                    url={challenge.mapOverlay}
                    bounds={JSON.parse(challenge.bounds)}
                  />
                )}
                {google && users && challenge.pathCoordinates && (
                  <Polyline
                    onLoad={onLoad}
                    path={challenge.pathCoordinates}
                    options={options}
                  />
                )}
                {markers}
                {paceMarker}
                {infoWindow}
                {waypoints}
              </>
            </GoogleMap>
          </div>
          {activity && (
            <Activity
              activity={activity}
              handleOpenEvModal={handleOpenEvModal}
              handleToggle={handleToggle}
            />
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className="loader"></div>
          <p>Please wait</p>
        </div>
      )}
      <WaypointModal
        isOpen={isWpModalOpen}
        onClose={handleCloseWpModal}
        getUser={getUser}
        waypoint={waypoint}
        status={activity?.status?.isCompleted}
      />
      <EvidenceModal
        isOpen={isEvModalOpen}
        onClose={handleCloseEvModal}
        getUser={getUser}
        status={activity?.status?.isCompleted}
        app={app}
      />
      {/* Render the Completion Modal if it hasn't been shown for this challenge */}
      {showCompletionModal && (
        <CompletionModal
          onClose={() => {
            setShowCompletionModal(false);
            localStorage.setItem(`completionModalShown_${productId}`, "true");
          }}
        />
      )}
    </div>
  ) : (
    <>Loading the Map!</>
  );
}

export default React.memo(ViewMap);
